import React, {Suspense} from 'react';
import {BrowserRouter as Router, Redirect, Route, Switch,} from "react-router-dom";
import {Provider} from "react-redux";

import {store} from "./redux/Store";

import MenuBar from "./components/MenuBar";

import './App.scss';
import './custom.scss';
import {Processing, ScreenLayout} from './components/UIElements';
import AboutPage from "./components/AboutPage";
import Footer from './components/Footer';
import {Message, Toasts} from "./components/PopUps";
import {PrivateRoute} from "./components/Authentication";
import {fetchDpis, fetchEntrepreneurs, fetchFolders, fetchKveds, heartbeat, logout} from "./redux/Actions";
import EntrepreneurBar from "./components/EntrepreneursBar";

const DocumentsView = React.lazy(() => import('./components/Documents'));
const TransactionsView = React.lazy(() => import('./components/Transactions'));
const EntrepreneurOverview = React.lazy(() => import('./components/EntrepreneurOverview'));
const EntrepreneurEditor = React.lazy(() => import('./components/EntrepreneurEditor'));
const LoginPage = React.lazy(() => import('./components/Authentication'));
const Administration = React.lazy(() => import("./components/Administration"));
const ProfilePreferences = React.lazy(() => import("./components/ProfilePreferences"));
const OrganizationPreferences = React.lazy(() => import("./components/OrganizationPreferences"));
const RegistrationPage = React.lazy(() => import("./components/RegistrationPage"));
const TasksView = React.lazy(() => import("./components/Tasks"));

function PayTaxApp() {

    store.dispatch(heartbeat());

    let wasAuthenticated = false;
    store.subscribe(() => {
        if (!wasAuthenticated && store.getState().configuration.isAuthenticated) {
            wasAuthenticated = true;
            store.dispatch(fetchEntrepreneurs());
            store.dispatch(fetchFolders());
            store.dispatch(fetchDpis());
            store.dispatch(fetchKveds());
        }
    });

    return (
        <Provider store={store}>
            <Router>
                <Processing/>
                <MenuBar/>
                <EntrepreneurBar />
                <Toasts/>
                <Message/>
                <Suspense fallback={<Processing force={true}/>}>
                    <Switch>
                        <Route exact path="/">
                            <AboutPage/>
                        </Route>
                        <PrivateRoute path={"/main"}>
                            <ScreenLayout />
                        </PrivateRoute>
                        <PrivateRoute path="/entrepreneurs/:id/transactions">
                            <TransactionsView/>
                        </PrivateRoute>
                        <PrivateRoute path="/entrepreneurs/:id/documents">
                            <DocumentsView/>
                        </PrivateRoute>
                        <PrivateRoute path="/entrepreneurs/:id/edit">
                            <EntrepreneurEditor />
                        </PrivateRoute>
                        <PrivateRoute path="/entrepreneurs/create">
                            <EntrepreneurEditor />
                        </PrivateRoute>
                        <PrivateRoute path="/entrepreneurs/:id">
                            <EntrepreneurOverview />
                        </PrivateRoute>
                        <PrivateRoute path="/tasks">
                            <TasksView/>
                        </PrivateRoute>
                        <PrivateRoute path="/profile">
                            <ProfilePreferences/>
                        </PrivateRoute>
                        <PrivateRoute path="/organization">
                            <OrganizationPreferences/>
                        </PrivateRoute>
                        <Route path="/login">
                            <LoginPage/>
                        </Route>
                        {
                            (process.env.REACT_APP_TARGET === "SERVER" || process.env.REACT_APP_TARGET === undefined) &&
                            <Route path={"/registration"}>
                                <RegistrationPage/>
                            </Route>
                        }
                        {
                            (process.env.REACT_APP_TARGET === "SERVER" || process.env.REACT_APP_TARGET === undefined) &&
                            <Route path="/admin">
                                <Administration/>
                            </Route>
                        }
                        <Route path="/logout" component={() => {
                            window.sessionStorage.clear();
                            store.dispatch(logout());
                            return <Redirect to={"/"}/>;
                        }}/>
                        <Route path="*">
                            <div style={{background: 'url(/404.svg) no-repeat center'}}
                                 className="content-vertical content-centered section-height"/>
                        </Route>
                    </Switch>
                </Suspense>
                <Footer/>
            </Router>
        </Provider>
    )
}

export default PayTaxApp;
