import {Actions} from "./Actions";

export function rootReducer(state, action) {
    return {
        configuration: configurationReducer(state.configuration, action),
        message: messageReducer(state.message, action),
        entrepreneurs: entrepreneursReducer(state.entrepreneurs, action),
        folders: foldersReducer(state.folders, action),
        transactions: transactionsReducer(state.transactions, action),
        profile: profileReducer(state.profile, action),
        organization: organizationReducer(state.organization, action),
        toasts: toastsReducer(state.toasts, action),
        tasks: tasksReducer(state.tasks, action),
        dpis: dpiReducer(state.dpis, action),
        kveds: kvedReducer(state.kveds, action),
    };
}

function configurationReducer(state, action) {
    switch (action.type) {
        case Actions.CONFIGURATION_IS_AUTHENTICATED:
            return {
                ...state,
                isAuthenticated: action.data
            };
        case Actions.CONFIGURATION_PROCESSING_STEP:
            return {
                ...state,
                processingCounter: (state.processingCounter + action.data) < 0 ? 0 : (state.processingCounter + action.data)
            };
        default:
            return state;
    }
}

function messageReducer(state, action) {
    switch (action.type) {
        case Actions.MESSAGE_SHOW:
            return action.data;
        case Actions.MESSAGE_CLOSE:
            return {
                text: null,
                header: null,
                type: null
            };
        default:
            return state;
    }
}

function entrepreneursReducer(state, action) {
    let index;
    let entrepreneur;
    switch (action.type) {
        case Actions.ENTREPRENEUR_FETCH:
            return action.data.sort((a, b) => stringPropertyComparatorComparator(a, b, 'lastName'));
        case Actions.ENTREPRENEUR_ADD:
            return [...state, ...action.data].sort((a, b) => stringPropertyComparatorComparator(a, b, 'lastName'));
        case Actions.ENTREPRENEUR_UPDATE:
            return [...state.filter(e => e.id !== action.data.id), action.data].sort((a, b) => stringPropertyComparatorComparator(a, b, 'lastName'));
        case Actions.ENTREPRENEUR_DELETE:
            return [...state.filter(e => e.id !== action.data)];
        case Actions.ENTREPRENEUR_DELETE_FOLDER:
            index = state.findIndex(e => e.id === action.data);
            entrepreneur = state[index];
            return state.toSpliced(index, 1, {...entrepreneur, folder: null});
        case Actions.ENTREPRENEUR_SET_FOLDER:
            index = state.findIndex(e => e.id === action.data.id);
            entrepreneur = state[index];
            return state.toSpliced(index, 1, action.data);
        default:
            return state;
    }
}

function foldersReducer(state, action) {
    switch (action.type) {
        case Actions.FOLDERS_SET:
            return action.data;
        case Actions.FOLDERS_ADD:
            return [...state, action.data];
        case Actions.FOLDERS_DELETE:
            return state.filter(f => f.id !== action.data)
                .sort((a, b) => stringPropertyComparatorComparator(a, b, 'name'));
        case Actions.FOLDERS_UPDATE:
            let index = state.findIndex(f => f.id === action.data.id);
            return state.toSpliced(index, 1, action.data)
                    .sort((a, b) => stringPropertyComparatorComparator(a, b, 'name'));
        default:
            return state;
    }
}

function stringPropertyComparatorComparator(a, b, property) {
    if (a[property].toUpperCase() > b[property].toUpperCase()) {
        return 1;
    }
    if (a[property].toUpperCase() < b[property].toUpperCase()) {
        return -1;
    }
    return 0;
}

function objectDateComparator(a, b) {
    if (new Date(a.date).getTime() > new Date(b.date).getTime()) {
        return 1;
    }
    if (new Date(a.date).getTime() < new Date(b.date).getTime()) {
        return -1;
    }
    return 0;
}

function tasksReducer(state, action) {
    switch (action.type) {
        case Actions.TASKS_ADD:
            return [...action.data, ...state];
        case Actions.TASKS_UPDATE:
            const taskIndex = state.findIndex(t => t.id === action.data.id);
            return [...state.slice(0, taskIndex),  action.data, ...state.slice(taskIndex + 1, state.length)];
        case Actions.TASKS_FETCH:
            return action.data;
        case Actions.TASKS_DELETE:
            return state.filter(t => t.id !== action.data)
        default:
            return state;
    }
}

function transactionsReducer(state, action) {
    switch (action.type) {
        case Actions.TRANSACTION_UPDATE_PERSISTED:
            const transactionIndex = state.persisted.findIndex(t => t.id === action.data.id);
            return {
                ...state,
                persisted: [...state.persisted.slice(0, transactionIndex), action.data, ...state.persisted.slice(transactionIndex + 1, state.persisted.length)]
            };
        case Actions.TRANSACTIONS_DELETE_PERSISTED:
            return {
                ...state,
                persisted: state.persisted.filter(t => !action.data.includes(t.id))
            };
        case Actions.TRANSACTIONS_SET_PERSISTED:
            return {
                ...state,
                persisted: action.data.sort(objectDateComparator)
            };
        case Actions.TRANSACTIONS_SET_PARSED:
            return {
                ...state,
                parsed: action.data.sort(objectDateComparator)
            };

        case Actions.TRANSACTIONS_SET_TYPE_FILTER:
            return {
                ...state,
                filters: {
                    ...state.filters,
                    type: action.data
                }
            };
        case Actions.TRANSACTIONS_SET_CURRENCY_FILTER:
            return {
                ...state,
                filters: {
                    ...state.filters,
                    currency: action.data
                }
            };
        case Actions.TRANSACTIONS_SET_AREA_FILTER:
            return {
                ...state,
                filters: {
                    ...state.filters,
                    incomeExpenseAreaFilter: action.data
                }
            };
        case Actions.TRANSACTIONS_SET_PERIOD_TYPE:
            return {
                ...state,
                periodType: action.data
            };
        default:
            return state;
    }
}

function profileReducer(state, action) {
    switch (action.type) {
        case Actions.PROFILE_SET:
            return action.data;
        default:
            return state;
    }
}

function organizationReducer(state, action) {
    switch (action.type) {
        case Actions.ORGANIZATION_SET:
            return action.data;
        case Actions.ORGANIZATION_DELETE_MEMBER:
            return {
                ...state,
                members: state.members.filter(m => m.id !== action.data)
            };
        case Actions.ORGANIZATION_ADD_MEMBER:
            return {
                ...state,
                members: [...state.members, action.data]
            };
        default:
            return state;
    }
}

function dpiReducer(state, action) {
    switch (action.type) {
        case Actions.SET_DPIS:
            return [...action.data];
        default:
            return state;
    }
}

function kvedReducer(state, action) {
    switch (action.type) {
        case Actions.SET_KVEDS:
            return [...action.data];
        default:
            return state;
    }
}

function toastsReducer(state, action) {
    switch (action.type) {
        case Actions.ADD_TOAST:
            return [action.data, ...state];
        case Actions.REMOVE_TOAST:
            return state.filter(t => t.id !== action.data);
        default:
            return state;
    }
}