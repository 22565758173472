export class Logger {

    static log(message) {
        // This is for future logging back to server
        if (process.env.NODE_ENV === 'development') {
            console.log(message);
        }
    }

    static exception(error, errorInfo) {
        // This is for future exception reporting back to server
        if (process.env.NODE_ENV === 'production') {
            fetch(`${process.env.REACT_APP_HOST}/api/v1/report/error`, {
                method: "POST",
                body: JSON.stringify({message: error.message, name: error.name, stack: error.stack}),
                headers: {'content-type': 'application/json'}
            }).catch(Logger.log);
        } else {
            console.error(error);
        }
    }
}