import React, {useState} from 'react';
import {useSelector} from "react-redux";
import {selectIsProcessing} from "../redux/Selectors";
import {Logger} from "../services/Logging";
import arrow from "../resources/images/arrow-right.svg";
import done from "../resources/icons/done.svg";
import close from "../resources/icons/close.svg";

// Bootstrap
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import ButtonGroup from "react-bootstrap/ButtonGroup";

export function CancellableInput({initialValue = '', onOk, onCancel, ...other}) {
    const [value, setValue] = useState(initialValue);

    return (
        <ButtonGroup {...other}>
            <Form.Control value={value} onChange={e => setValue(e.target.value)} />
            <Button variant={"success"} onClick={() => {onOk(value); setValue('')}}><img src={done} alt={"готово"}/></Button>
            <Button variant={"warning"} onClick={() => {onCancel(); setValue('')}}><img src={close} alt={"скасувати"}/></Button>
        </ButtonGroup>
    );
}

export function ScreenLayout({title, isContent = false, children}) {
    return(
        <ErrorBoundaries>
            <Row style={{minHeight: '100vh'}} className={"m-0 w-100"}>
                {
                    isContent ?
                        <Col>
                            <h1 className="title">{title}</h1>
                            { children }
                        </Col>
                        :
                        <Col>
                            <HintView />
                        </Col>
                }
            </Row>
        </ErrorBoundaries>
    );
}

export function HintView() {
    return(
        <Container style={{filter: 'opacity(50%)'}} className={"h-75 w-100 d-flex align-items-center justify-content-center"}>
            <Row>
                <Col className={"text-nowrap text-center"}>
                    <h1>Оберіть ФОП зі списку справа</h1>
                </Col>
                <Col className={"text-center"}>
                    <img src={arrow} alt={"Оберіть ФОП зі списку справа"} />
                </Col>
            </Row>
        </Container>
    );
}

export function WarningModal({show, header, message, onOk, onCancel}) {
    if (!header) {
        header = "Увага"
    }
    return(
        <Modal show={show}>
            <Modal.Header className={"bg-warning"}>{ header }</Modal.Header>
            <Modal.Body>
                { message }
            </Modal.Body>
            <Modal.Footer>
                { onOk && <Button variant={"warning"} onClick={onOk}>{onCancel ? "Продовжити" : "Зрозуміло"}</Button> }
                { onCancel && <Button variant={"success"} onClick={onCancel}>Відміна</Button> }
            </Modal.Footer>
        </Modal>
    );
}

export function Processing({force}) {
    const isProcessing = useSelector(selectIsProcessing);

    if (force ? force : isProcessing) {
        return(
            <div id="processing-progress" className="content-vertical content-centered">
                <span className={"increasing-text-animation"}>PayTax.services</span>
            </div>
        );
    }
    return null;
}

export function LogoBranding() {
    return(
        <React.Fragment>
            <span className={"fs-2 fst-italic"}>PayTax</span><span className={"fst-italic fw-lighter fs-5 text-success"}> .services</span>
        </React.Fragment>
    );
}

class ErrorBoundaries extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            hasError: false
        };
    }

    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    componentDidCatch(error, errorInfo) {
        Logger.exception(error, errorInfo);
    }

    render() {
        if (this.state.hasError) {
            return(
                <Container style={{height: '100vh'}} className={"d-flex flex-column justify-content-center align-items-center w-100"}>
                    <Row className={"fs-2"}>
                        Нажаль сталась помилка ;(
                    </Row>
                    <Row className={"fs-6"}>
                        <span>Поверніться <a href={"https://paytax.services"}>на головну</a> сторінку!</span>
                    </Row>
                </Container>
            );
        }

        return this.props.children;
    }
}