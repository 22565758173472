import React, {useEffect, useRef, useState} from "react";
import SvgTime from "../animations/SvgTimeAnimations";
import SvgGirlAndPcAnimation from "../animations/SvgGirlAndPcAnimation";
import SvgDatabase from "../animations/SvgDatabase";
import SvgCalculator from "../animations/SvgCalculator";
import SvgDocuments from "../animations/SvgDocuments";
import SvgCooperation from "../animations/SvgCooperation";

import {useDispatch} from "react-redux";
import {stepProcessingCounter} from "../redux/Actions";
import SvgEncryption from "../animations/SvgEncryption";

import presentation from "../resources/images/presentation.svg";

// Bootstrap
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Container from "react-bootstrap/Container";

export default function About() {

    useEffect(() => {
        const intersectionObserver = new IntersectionObserver(entries => {
            entries.forEach(entry => {
                entry.target.classList.toggle("non-transitioned", entry.isIntersecting);
            });
        }, {threshold: 0.3});
        const transitionable = document.getElementsByClassName("transitionable");
        if (transitionable) {
            for (let i = 0; i < transitionable.length; ++i) {
                intersectionObserver.observe(transitionable.item(i));
            }
        }
    }, [])

    return(
        <React.Fragment>
            <InfoRow style={{backgroundColor: 'rgba(158,217,204,1)'}}>
                <SvgGirlAndPcAnimation height={150} className={"transitionable from-right"} />
                <h1 className="title transitionable from-left">Бухгалтерський Облік</h1>
            </InfoRow>
            <InfoRow style={{backgroundColor: 'rgba(158,217,204,1)'}}>
                <h1 className="title transitionable from-right">Автоматизація процесів</h1>
                <SvgTime height={150} className={"transitionable from-left"} />
            </InfoRow>

            <InfoRow>
                <SvgCalculator height={200} className={"transitionable from-left"} />
                <TextBlock title="Ведення бухгалтерії ФОП" className={"transitionable from-right"}>
                    Транзакції ФОП імпортуються з виписок більшості популярних банків, їм призначають статті доходів і витрат, які відтоді будуть визначатися автоматично,
                    а на основі операцій клієнта ми підрахуємо єдиний податок за квартал чи звітний період і згенеруємо <b>Книгу обліку</b> та <b>Податкову декларацію</b>, яку можна завантажити
                    в Електронному Кабінеті підприємця.
                </TextBlock>
            </InfoRow>
            <InfoRow>
                <TextBlock title="Первинні документи" className={"transitionable from-left"}>
                    Максимально простий процес створення найнеобхідніших первинних документів - <b>інвойс</b>, <b>акт виконаних робіт</b> та <b>рахунок фактура</b>. <br />
                    Усі можливі поля автоматично заповнюються з даних підприємця ти попередньо створених документів.
                </TextBlock>
                <SvgDocuments height={150} className={"transitionable from-right"} />
            </InfoRow>

            <InfoRow style={{backgroundColor: 'rgba(158,217,204,1)'}}>
                <SvgCooperation height={200} className={"transitionable from-left"} />
                <TextBlock title="Співпраця" className={"transitionable from-right"}>
                    Робота як в офісі - адже кожен клієнт отримує персональну віртуальну організацію, в якій можна створювати будь-яку кількість профілів для колег.<br/><br/>

                    В межах однієї організації усі мають доступ до повного списку підприємців, їхніх транзакцій та документів - тож Ви легко можете організувати спільну роботу кількох бухгалтерів.
                </TextBlock>
            </InfoRow>
            <InfoRow style={{backgroundColor: 'rgba(158,217,204,1)'}}>
                <TextBlock title="База даних" className={"transitionable from-left"}>
                    Дозволяє вести облік доходів та витрат, зберігати адреси, телефони, банківські реквізити, ДПІ, КВЕДи чи власні нотатки
                    для кожного клієнта і надає легкий та зручний доступ до інформації.
                </TextBlock>
                <SvgDatabase height={200} className={"transitionable from-right"} />
            </InfoRow>

            <InfoRow>
                <SvgEncryption height={200} className={"transitionable from-left"} />
                <TextBlock title="Безпека" className={"transitionable from-right"}>
                    Усі дані ФОП, транзакції та документи зберігаються в зашифрованому вигляді<br />
                    і доступні лише в межах однієї організації.<br /><br />
                    Тож ви можете бути спокійними за конфіденційність ваших клієнтів.
                </TextBlock>
            </InfoRow>
            <Container className={"w-100 text-center transitionable from-top"}><h4 className={"title"}>Залишились запитання?</h4></Container>
            <InfoRow>
                <div className={"mx-5 transitionable from-left"}>
                    <Contact />
                </div>
                <div className={"mx-5 d-flex flex-column align-items-center justify-content-center transitionable from-right"}>
                    <img height={200} src={presentation} alt={"презентація"}/>
                    <Button href={"/PayTaxOverview.pdf"} className={"mt-3"} style={{width: '20rem'}} variant={"secondary"}>
                            <span>
                                Завантажити презентацію
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                     className="ms-2 bi bi-chevron-bar-down" viewBox="0 0 16 16">
                                    <path fillRule="evenodd"
                                          d="M3.646 4.146a.5.5 0 0 1 .708 0L8 7.793l3.646-3.647a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 0-.708zM1 11.5a.5.5 0 0 1 .5-.5h13a.5.5 0 0 1 0 1h-13a.5.5 0 0 1-.5-.5z"/>
                                </svg>
                            </span>
                    </Button>
                </div>
            </InfoRow>
            <InfoRow>
                <Button href={"/registration"} style={{width: '20rem'}}>Зареєструватись</Button>
            </InfoRow>
        </React.Fragment>
    );
}

function InfoRow({children, ...rest}) {
    return(
        <div className="p-5 d-flex flex-column flex-md-row align-items-center justify-content-center" {...rest}>
            { children }
        </div>
    );
}

function TextBlock({title, children, className}) {
    return(
        <div style={{maxWidth: '20rem',}} className={`mx-5 ${className}`}>
            <h3 className="title">
                { title }
            </h3>
            <p>
                { children }
            </p>
        </div>
    );
}

function Contact() {
    const formRef = useRef();

    const dispatch = useDispatch();

    const [name, setName] = useState('');
    const [mail, setMail] = useState('');
    const [text, setText] = useState('');

    const submitRequest = (e) => {
        e.preventDefault();
        dispatch(stepProcessingCounter(1));
        fetch(formRef.current.action, {
            method: formRef.current.method,
            body: new URLSearchParams(new FormData(formRef.current)),
            headers: { 'content-type': 'application/x-www-form-urlencoded' }
        }).finally(() => {
            setName('');
            setMail('');
            setText('');
            dispatch(stepProcessingCounter(-1));
        });
    };

    return(
       <Form className={"m-3"} ref={formRef} onSubmit={submitRequest} action={`${process.env.REACT_APP_HOST}/api/public/contacts`} method="post">
            <Row>
                <Col>
                    <FloatingLabel label={"Ім'я"}>
                        <Form.Control required type={"text"} placeholder={"Ім'я"} name={"name"} value={name} onChange={e => setName(e.target.value)}/>
                    </FloatingLabel>
                </Col>
                <Col>
                    <FloatingLabel label={"e-mail"}>
                        <Form.Control required type={"mail"} placeholder={"e-mail"} name={"email"} value={mail} onChange={e => setMail(e.target.value)}/>
                    </FloatingLabel>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Form.Control className={"w-100 mt-4"} as={"textarea"} rows={4} placeholder={"Повідомлення"} name={"text"} value={text} onChange={e => setText(e.target.value)} />
                </Col>
            </Row>
            <Row>
                <Col>
                    <Button variant={"secondary"} className={"mt-4 w-100"} type={"submit"}>Надіслати</Button>
                </Col>
            </Row>
        </Form>
    );
}