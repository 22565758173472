export const selectEntrepreneurs = state => state.entrepreneurs;
export const selectEntrepreneur = entrepreneurId => state => state.entrepreneurs.find(e => e.id === Number(entrepreneurId));

export const selectFolders = state => state.folders;

export const selectTasks = state => state.tasks;
export const selectPersistedTransactions = state => state.transactions.persisted;
export const selectParsedTransactions = state => state.transactions.parsed;
export const selectTypeFilter = state => state.transactions.filters.type;
export const selectCurrencyFilter = state => state.transactions.filters.currency;
export const selectIncomeExpenseAreaFilter = state => state.transactions.filters.incomeExpenseAreaFilter;
export const selectPeriodType = state => state.transactions.periodType;
export const selectIsAuthenticated = state => state.configuration.isAuthenticated;
export const selectIsProcessing = state => state.configuration.processingCounter !== 0;

export const selectDpis = state => state.dpis;
export const selectKveds = state => state.kveds;

export const selectProfile = state => state.profile;

export const selectOrganization = state => state.organization;

export const selectToasts = state => state.toasts;
export const selectMessageText = state => state.message.text;
export const selectMessageHeader = state => state.message.header;
export const selectMessageType = state => state.message.type;
