import React, {useState} from "react";

// Bootstrap
import ToastContainer from "react-bootstrap/ToastContainer";
import Toast from "react-bootstrap/Toast";
import {useDispatch, useSelector} from "react-redux";
import {selectMessageHeader, selectMessageText, selectMessageType, selectToasts} from "../redux/Selectors";
import {closeMessage, removeToast} from "../redux/Actions";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

export const ERROR = "error";
export const WARNING = "warning";

export function Toasts() {
    const toasts = useSelector(selectToasts);

    return(
        <div >
            <ToastContainer style={{zIndex: '100', position: 'fixed', top: '4rem', right: '1rem'}}>
                {
                    toasts.map(toast => <AutoHideToast key={toast.id} id={toast.id} header={toast.header} message={toast.message} /> )
                }
            </ToastContainer>
        </div>
    );
}

function AutoHideToast({id, header, message}) {
    const dispatch = useDispatch();

    const [show, setShow] = useState(true);
    return(
        <Toast bg={"warning"} show={show} onClose={() => {
            setShow(false);
            dispatch(removeToast(id));
        }} delay={5000} autohide={true}>
            <Toast.Header>{header}</Toast.Header>
            <Toast.Body>
                {message}
            </Toast.Body>
        </Toast>
    );
}

export function Message() {
    const dispatch = useDispatch();

    let header = useSelector(selectMessageHeader);
    let text = useSelector(selectMessageText);
    let type = useSelector(selectMessageType);

    if (!header) {
        header = "Увага!";
    }

    return(
        <Modal show={text !== null}>
            <Modal.Header className={type === ERROR ? "bg-danger" : "bg-warning"}>{ header }</Modal.Header>
            <Modal.Body>
                { text }
            </Modal.Body>
            <Modal.Footer>
                <Button variant={type === ERROR ? "danger" : "warning"} onClick={() => dispatch(closeMessage())}>Зрозуміло</Button>
            </Modal.Footer>
        </Modal>
    );
}