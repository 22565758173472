import {applyMiddleware, compose, createStore} from "redux";
import thunkMiddleware from "redux-thunk";

import {rootReducer} from "./Reducers";

const defaultState = {
    configuration: {
        isAuthenticated: false,
        processingCounter: 0,
    },
    message: {
        text: null,
        header: null,
        type: null
    },
    profile: {
        firstName: "",
        lastName: "",
        roles: []
    },
    organization: {
        name: undefined,
        members: [],
    },
    transactions: {
        persisted: [],
        parsed: [],
        filters : {
            type: '',
            currency: '',
            incomeExpenseAreaFilter: '',
        },
        /**
         * 2 - 1 квартал
         * 3 - півріччя
         * 4 - 9 місяців
         * 5 - рік
         */
        periodType: '2',
    },
    entrepreneurs: [],
    folders: [],
    tasks: [],
    toasts: [],
    dpis: [],
    kveds: [],
}

const composedThunkEnhancer = compose(
    applyMiddleware(thunkMiddleware)
     // ,window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
    )

export const store = createStore(rootReducer, defaultState, composedThunkEnhancer)