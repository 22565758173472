import React from "react";
import mail_icon from "../resources/icons/mail.svg";
import {version as appVersion} from "../../package.json";
import {LogoBranding} from "./UIElements";

export default function Footer() {
    return(
        <div className={"shadow-lg"} id="footer">
            <table>
                <tbody>
                    <tr>
                        <td><LogoBranding /></td>
                    </tr>
                    <tr>
                        <td><a href="mailto:contacts.paytax@gmail.com">Надіслати листа <img src={mail_icon} alt="повідомлення" height={16} /></a></td>
                    </tr>
                </tbody>
            </table>

            <table>
                <tbody>
                    <tr>
                        <td><a href={"/agreement.html"} target={"_blank"} rel={"noreferrer"}>Угода користувача</a></td>
                    </tr>
                </tbody>
            </table>

            <table>
                <tbody>
                    <tr>
                        <td><small className={"text-muted"}>Версія: {appVersion}</small></td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
}