import {Server4xxError} from "../exceptions/Exceptions";
import * as rest from "../services/RestService";
import {Logger} from "../services/Logging";

export const Actions = {
    CONFIGURATION_IS_AUTHENTICATED: 'configuration/isAuthenticated',
    CONFIGURATION_PROCESSING_STEP: 'configuration/processingStep',

    ENTREPRENEUR_ADD: 'entrepreneur/add',
    ENTREPRENEUR_FETCH: 'entrepreneur/fetch',
    ENTREPRENEUR_UPDATE: 'entrepreneur/update',
    ENTREPRENEUR_DELETE: 'entrepreneur/delete',
    ENTREPRENEUR_SET_FOLDER:  'entrepreneur/setFolder',
    ENTREPRENEUR_DELETE_FOLDER:  'entrepreneur/deleteFolder',

    TRANSACTIONS_SET_PERSISTED: 'transactions/persisted/set',
    TRANSACTION_UPDATE_PERSISTED: 'transactions/persisted/update',
    TRANSACTIONS_DELETE_PERSISTED: 'transactions/persisted/delete',
    TRANSACTIONS_SET_PARSED: 'transactions/parsed/set',
    TRANSACTIONS_SET_TYPE_FILTER: 'transactions/setOperationFilter',
    TRANSACTIONS_SET_CURRENCY_FILTER: 'transactions/setCurrencyFilter',
    TRANSACTIONS_SET_AREA_FILTER: 'transactions/setAreaFilter',
    TRANSACTIONS_SET_PERIOD_TYPE: 'transactions/setPeriodType',

    TASKS_ADD: 'tasks/add',
    TASKS_UPDATE: 'tasks/update',
    TASKS_DELETE: 'tasks/delete',
    TASKS_FETCH: 'tasks/fetch',

    PROFILE_SET: 'profile/set',

    ORGANIZATION_SET: 'organization/set',
    ORGANIZATION_ADD_MEMBER: 'organization/addMember',
    ORGANIZATION_DELETE_MEMBER: 'organization/deleteMember',

    FOLDERS_SET: 'folders/set',
    FOLDERS_ADD: 'folders/add',
    FOLDERS_UPDATE: 'folders/update',
    FOLDERS_DELETE: 'folders/delete',

    SET_DPIS: 'configuration/setDpis',
    SET_KVEDS: 'configuration/setKveds',
    ADD_TOAST: 'configuration/addToast',
    REMOVE_TOAST: 'configuration/removeToast',

    MESSAGE_SHOW: 'message/show',
    MESSAGE_CLOSE: 'message/close',
};

export function showMessage(header, text, type) {
    return {type: Actions.MESSAGE_SHOW, data: {header, text, type}};
}

export function closeMessage() {
    return {type: Actions.MESSAGE_CLOSE};
}

export function setIsAuthenticated(isAuthenticated) {
    return {type: Actions.CONFIGURATION_IS_AUTHENTICATED, data: isAuthenticated};
}

export function stepProcessingCounter(step) {
    return {type: Actions.CONFIGURATION_PROCESSING_STEP, data: step};
}

export function setTypeFilter(filter) {
    return {type: Actions.TRANSACTIONS_SET_TYPE_FILTER, data: filter};
}

export function setCurrencyFilter(filter) {
    return {type: Actions.TRANSACTIONS_SET_CURRENCY_FILTER, data: filter};
}

export function setAreaFilter(filter) {
    return {type: Actions.TRANSACTIONS_SET_AREA_FILTER, data: filter};
}

export function setPeriodType(periodType) {
    return {type: Actions.TRANSACTIONS_SET_PERIOD_TYPE, data: periodType};
}

/**
 * Each Toast should have unique id
 */
let LOCAL_TOAST_ID = 0;
/**
 * @param toast {{header: String, message: String}}
 * @returns {{data, type: string}}
 */
export function addToast(toast) {
    ++LOCAL_TOAST_ID;
    return {type: Actions.ADD_TOAST, data: {id: LOCAL_TOAST_ID, ...toast}}
}

export function removeToast(id) {
    return {type: Actions.REMOVE_TOAST, data: id}
}

export function logout() {
    return async function logout(dispatch, getState) {
        try {
            await rest.logout();
            dispatch(setIsAuthenticated(false));
        } catch (e) {
            handleError(e, dispatch);
        }
    }
}

export function heartbeat() {
    return async function heartbeat(dispatch, getState) {
        try {
            await rest.heartbeat();
            dispatch(setIsAuthenticated(true));
        } catch (e) {
            if (e instanceof Server4xxError && e.response === 401) {
                dispatch(setIsAuthenticated(false));
            }
        }
    }
}

export function fetchDpis() {
    return async function fetchDpis(dispatch, getState) {
        dispatch(stepProcessingCounter(1));
        try {
            let dpis;
            if (window.sessionStorage.getItem("dpis")) {
                dpis = JSON.parse(window.sessionStorage.getItem("dpis"));
            } else {
                dpis = await rest.getDpis();
                window.sessionStorage.setItem("dpis", JSON.stringify(dpis));
            }
            dispatch({type: Actions.SET_DPIS, data: dpis})
        } catch (e) {
            handleError(e, dispatch);
        } finally {
            dispatch(stepProcessingCounter(-1));
        }
    }
}

export function fetchKveds() {
    return async function fetchKveds(dispatch, getState) {
        dispatch(stepProcessingCounter(1));
        try {
            let kveds;
            if (window.sessionStorage.getItem("kveds")) {
                kveds = JSON.parse(window.sessionStorage.getItem("kveds"));
            } else {
                kveds = await rest.getKveds();
                window.sessionStorage.setItem("kveds", JSON.stringify(kveds));
            }
            dispatch({type: Actions.SET_KVEDS, data: kveds})
        } catch (e) {
            handleError(e, dispatch);
        } finally {
            dispatch(stepProcessingCounter(-1));
        }
    }
}

export function fetchFolders() {
    return async function fetchFolders(dispatch, getState) {
        dispatch(stepProcessingCounter(1));
        try {
            let folders = await rest.getFolders();
            dispatch({type: Actions.FOLDERS_SET, data: folders})
        } catch (e) {
            handleError(e, dispatch);
        } finally {
            dispatch(stepProcessingCounter(-1));
        }
    }
}

export function createFolder(name) {
    return async function createFolder(dispatch, getState) {
        dispatch(stepProcessingCounter(1));
        try {
            let folder = await rest.postFolder({name});
            dispatch({type: Actions.FOLDERS_ADD, data: folder})
        } catch (e) {
            handleError(e, dispatch);
        } finally {
            dispatch(stepProcessingCounter(-1));
        }
    }
}

export function updateFolder(folder) {
    return async function updateFolder(dispatch, getState) {
        dispatch(stepProcessingCounter(1));
        try {
            folder = await rest.putFolder(folder);
            dispatch({type: Actions.FOLDERS_UPDATE, data: folder})
        } catch (e) {
            handleError(e, dispatch);
        } finally {
            dispatch(stepProcessingCounter(-1));
        }
    }
}

export function deleteFolder(folderId) {
    return async function deleteFolder(dispatch, getState) {
        dispatch(stepProcessingCounter(1));
        try {
            await rest.deleteFolder(folderId);
            dispatch({type: Actions.FOLDERS_DELETE, data: folderId})
        } catch (e) {
            handleError(e, dispatch);
        } finally {
            dispatch(stepProcessingCounter(-1));
        }
    }
}

export function updateEntrepreneurFolder(folderId, entrepreneurId) {
    return async function deleteFolder(dispatch, getState) {
        dispatch(stepProcessingCounter(1));
        try {
            const entrepreneur = await rest.putEntrepreneurFolder(folderId, entrepreneurId);
            dispatch({type: Actions.ENTREPRENEUR_SET_FOLDER, data: entrepreneur});
        } catch (e) {
            handleError(e, dispatch);
        } finally {
            dispatch(stepProcessingCounter(-1));
        }
    }
}

export function deleteEntrepreneurFolder(folderId, entrepreneurId) {
    return async function deleteFolder(dispatch, getState) {
        dispatch(stepProcessingCounter(1));
        try {
            await rest.deleteEntrepreneurFolder(folderId, entrepreneurId);
            dispatch({type: Actions.ENTREPRENEUR_DELETE_FOLDER, data: entrepreneurId});
        } catch (e) {
            handleError(e, dispatch);
        } finally {
            dispatch(stepProcessingCounter(-1));
        }
    }
}

export function fetchEntrepreneurs() {
    return async function getEntrepreneurs(dispatch, getState) {
        dispatch(stepProcessingCounter(1));
        try {
            let entrepreneurs = await rest.getEntrepreneurs();
            dispatch({type: Actions.ENTREPRENEUR_FETCH, data: entrepreneurs})
        } catch (e) {
            handleError(e, dispatch);
        } finally {
            dispatch(stepProcessingCounter(-1));
        }
    }
}

export function createEntrepreneur(entrepreneur, history) {
    return async function postEntrepreneur(dispatch, getState) {
        dispatch(stepProcessingCounter(1));
        try {
            entrepreneur = await rest.postEntrepreneur(entrepreneur);
            dispatch({type: Actions.ENTREPRENEUR_ADD, data: [entrepreneur]})
            history.push(`/entrepreneurs/${entrepreneur.id}`);
        } catch (e) {
            handleError(e, dispatch);
        } finally {
            dispatch(stepProcessingCounter(-1));
        }
    }
}

export function updateEntrepreneur(entrepreneur) {
    return async function putEntrepreneur(dispatch, getState) {
        dispatch(stepProcessingCounter(1));
        try {
            entrepreneur = await rest.putEntrepreneur(entrepreneur);
            dispatch({type: Actions.ENTREPRENEUR_UPDATE, data: entrepreneur})
        } catch (e) {
            handleError(e, dispatch);
        } finally {
            dispatch(stepProcessingCounter(-1));
        }
    }
}

export function deleteEntrepreneur(entrepreneurId) {
    return async function deleteEntrepreneur(dispatch, getState) {
        dispatch(stepProcessingCounter(1));
        try {
            await rest.deleteEntrepreneur(entrepreneurId);
            dispatch({type: Actions.ENTREPRENEUR_DELETE, data: entrepreneurId})
        } catch (e) {
            handleError(e, dispatch);
        } finally {
            dispatch(stepProcessingCounter(-1));
        }
    }
}

export function fetchTasks() {
    return async function fetchTasks(dispatch, getState) {
        dispatch(stepProcessingCounter(1));
        try {
            const tasks = await rest.getTasks();
            dispatch({type: Actions.TASKS_FETCH, data: tasks})
        } catch (e) {
            handleError(e, dispatch);
        } finally {
            dispatch(stepProcessingCounter(-1));
        }
    }
}
export function createTask(taskDescription) {
    return async function postTask(dispatch, getState) {
        dispatch(stepProcessingCounter(1));
        try {
            const tasks = await rest.postTask(taskDescription);
            dispatch({type: Actions.TASKS_ADD, data: tasks})
        } catch (e) {
            handleError(e, dispatch);
        } finally {
            dispatch(stepProcessingCounter(-1));
        }
    }
}

export function deleteTask(taskId, series = false) {
    return async function deleteEntrepreneur(dispatch, getState) {
        dispatch(stepProcessingCounter(1));
        try {
            await rest.deleteTask(taskId, series);
            dispatch({type: Actions.TASKS_DELETE, data: taskId})
        } catch (e) {
            handleError(e, dispatch);
        } finally {
            dispatch(stepProcessingCounter(-1));
        }
    }
}

export function updateTask(scheduled) {
    return async function putEntrepreneur(dispatch, getState) {
        dispatch(stepProcessingCounter(1));
        try {
            scheduled = await rest.putTask(scheduled);
            dispatch({type: Actions.TASKS_UPDATE, data: scheduled})
        } catch (e) {
            handleError(e, dispatch);
        } finally {
            dispatch(stepProcessingCounter(-1));
        }
    }
}

export function setPersistedTransactions(transactions) {
    return {type: Actions.TRANSACTIONS_SET_PERSISTED, data: transactions};
}

export function setParsedTransactions(transactions) {
    return {type: Actions.TRANSACTIONS_SET_PARSED, data: transactions};
}

export function fetchTransactions(entrepreneurId, fromDate, toDate) {
    return async function getTransactions(dispatch, getState) {
        dispatch(stepProcessingCounter(1));
        try {
            const transactions = await rest.getTransactions(entrepreneurId, fromDate, toDate)
            transactions.forEach(t => t['checked'] = true);
            dispatch(setPersistedTransactions(transactions));
        } catch (e) {
            handleError(e, dispatch);
        } finally {
            dispatch(stepProcessingCounter(-1));
        }
    }
}

export function uploadTransactions(entrepreneurId, transactionsToSave) {
    return async function postTransactionsReport(dispatch, getState) {
        dispatch(stepProcessingCounter(1));
        try {
            const transactions = await rest.postTransactions(entrepreneurId, transactionsToSave)
            transactions.forEach(t => t['checked'] = true);
            dispatch(setPersistedTransactions(transactions));
            dispatch(setParsedTransactions([]));
        } catch (e) {
            handleError(e, dispatch);
        } finally {
            dispatch(stepProcessingCounter(-1));
        }
    }
}

export function updateTransaction(transactionToUpdate) {
    return async function putTransaction(dispatch, getState) {
        dispatch(stepProcessingCounter(1));
        try {
            const transaction = await rest.putTransaction(transactionToUpdate);
            transaction.checked = transactionToUpdate.checked;
            dispatch({type: Actions.TRANSACTION_UPDATE_PERSISTED, data: transaction});
        } catch (e) {
            handleError(e, dispatch);
        } finally {
            dispatch(stepProcessingCounter(-1));
        }
    }
}

export function deleteTransactions(transactions) {
    return async function deleteTransactions(dispatch, getState) {
        dispatch(stepProcessingCounter(1));
        try {
            await rest.deleteTransactions(transactions.map(t => t.id));
            dispatch({type: Actions.TRANSACTIONS_DELETE_PERSISTED, data: transactions.map(t => t.id)});
        } catch (e) {
            handleError(e, dispatch);
        } finally {
            dispatch(stepProcessingCounter(-1));
        }
    }
}

export function fetchOrganizationData() {
    return async function getProfileData(dispatch, getState) {
        try {
            const data = await rest.getOrganizationData();
            dispatch({type: Actions.ORGANIZATION_SET, data});
        } catch (e) {
            handleError(e, dispatch);
        }
    }
}

export function fetchProfileData() {
    return async function getProfileData(dispatch, getState) {
        try {
            const data = await rest.getProfileData();
            dispatch({type: Actions.PROFILE_SET, data});
        } catch (e) {
            handleError(e, dispatch);
        }
    }
}

export function updateProfileData(profile) {
    return async function updateProfileData(dispatch, getState) {
        dispatch(stepProcessingCounter(1));
        try {
            const user = await rest.updateProfileData(profile);
            dispatch({type: Actions.PROFILE_SET, data: user});
        } catch (e) {
            handleError(e, dispatch);
        } finally {
            dispatch(stepProcessingCounter(-1));
        }
    }
}

export function updateProfilePassword(passwordDto) {
    return async function updateProfilePassword(dispatch, getState) {
        dispatch(stepProcessingCounter(1));
        try {
            await rest.updateProfilePassword(passwordDto);
        } catch (e) {
            handleError(e, dispatch);
        } finally {
            dispatch(stepProcessingCounter(-1));
        }
    }
}

export function updateOrganizationAccessKey(organization) {
    return async function updateOrganizationAccessKey(dispatch, getState) {
        dispatch(stepProcessingCounter(1));
        try {
            await rest.updateOrganizationAccessKey(organization);
        } catch (e) {
            handleError(e, dispatch);
        } finally {
            dispatch(stepProcessingCounter(-1));
        }
    }
}

export function createOrganizationMember(employee) {
    return async function createOrganizationMember(dispatch, getState) {
        dispatch(stepProcessingCounter(1));
        try {
            const member = await rest.createOrganizationMember(employee);
            dispatch({type: Actions.ORGANIZATION_ADD_MEMBER, data: member});
        } catch (e) {
            handleError(e, dispatch);
        } finally {
            dispatch(stepProcessingCounter(-1));
        }
    }
}

export function updateOrganizationMemberRole(employeeId, role) {
    return async function updateOrganizationMemberRole(dispatch, getState) {
        dispatch(stepProcessingCounter(1));
        try {
            await rest.updateOrganizationMemberRole(employeeId, role);
        } catch (e) {
            handleError(e, dispatch);
        } finally {
            dispatch(stepProcessingCounter(-1));
        }
    }
}

export function deleteOrganizationMember(employeeId) {
    return async function deleteOrganizationMember(dispatch, getState) {
        dispatch(stepProcessingCounter(1));
        try {
            await rest.deleteOrganizationMember(employeeId);
            dispatch({type: Actions.ORGANIZATION_DELETE_MEMBER, data: employeeId});
        } catch (e) {
            handleError(e, dispatch);
        } finally {
            dispatch(stepProcessingCounter(-1));
        }
    }
}

function handleError(e, dispatch) {
    Logger.log(e);
    if (e instanceof Server4xxError) {
        if (e.response === 401) {
            dispatch(setIsAuthenticated(false));
            dispatch(addToast({header: `Помилка ${e.response}`, message: 'Пройдіть авторизацію!'}));
        } else if (e.response === 403) {
            dispatch(addToast({header: `Помилка ${e.response}`, message: `Немає повноважень ${e.method}: ${e.url}`}));
        } else if (e.response === 404) {
            dispatch(addToast({header: `Помилка ${e.response}`, message: `Не знайдено ${e.method}: ${e.url}`}));
        } else {
            dispatch(addToast({header: `Помилка ${e.response}`, message: `${e.method}: ${e.url}\n${e?.message}`}));
            Logger.exception(e);
        }
    } else {
        dispatch(addToast({header: `Помилка ${e.response}`, message: `${e.method}: ${e.url}\n${e?.message}`}));
        Logger.exception(e);
    }
}