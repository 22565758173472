export class Server5xxError extends Error {
    constructor(url, method, response, ...params) {
        super(params);

        this.url = url;
        this.method = method;
        this.response = response;
        this.name = 'Server5xxError';
    }
}

export class Server4xxError extends Error {
    constructor(url, method, response, ...params) {
        super(params);

        this.url = url;
        this.method = method;
        this.response = response;
        this.name = 'Server4xxError';
    }
}