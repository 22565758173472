import React from "react";
import {useSelector} from "react-redux";
import {selectIsAuthenticated} from "../redux/Selectors";
import {Link, useLocation} from "react-router-dom";

// Bootstrap
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from 'react-bootstrap/Navbar'
import NavDropdown from "react-bootstrap/NavDropdown";
import {LogoBranding} from "./UIElements";

export default function MenuBar() {
    const isAuthenticated = useSelector(selectIsAuthenticated);

    return(
        <Navbar className={"shadow"} collapseOnSelect expand="md" >
            <Container>
                <Navbar.Brand href={isAuthenticated ? "/main" : "/"}><LogoBranding /></Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Nav>
                {
                    isAuthenticated
                        ?
                        <NavigationBar />
                        :
                        <Nav.Link as={'div'}><Link className={"text-decoration-none text-reset"} to={"/login"}>Вхід</Link></Nav.Link>
                }
                </Nav>
            </Container>
        </Navbar>
    );
}

function NavigationBar() {
    let locations = useLocation().pathname.split("/");
    let id = locations.length > 2 ? locations[2] : undefined;
    id = isNaN(id) ? undefined : Number(id);
    return(
        <Navbar.Collapse id="responsive-navbar-nav">
            { id && <Nav.Link as={'div'}><Link className={"text-decoration-none text-reset"} to={`/entrepreneurs/${id}/transactions`}>Транзакції</Link></Nav.Link> }
            { id && <Nav.Link as={'div'}><Link className={"text-decoration-none text-reset"} to={`/entrepreneurs/${id}/documents`}>Документи</Link></Nav.Link> }
            { id && <Nav.Link as={'div'}><Link className={"text-decoration-none text-reset"} to={`/entrepreneurs/${id}`}>Підприємець</Link></Nav.Link> }

            <NavDropdown className={"border"} title={"Більше"}>
                <NavDropdown.Item as={'div'}><Link className={"text-decoration-none text-reset"} to={"/profile"}>Профіль</Link></NavDropdown.Item>
                <NavDropdown.Item as={'div'}><Link className={"text-decoration-none text-reset"} to={"/organization"}>Організація</Link></NavDropdown.Item>
                <NavDropdown.Item as={'div'}><Link className={"text-decoration-none text-reset"} to={"/tasks"}>Завдання</Link></NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item as={'div'}><Link className={"text-decoration-none text-reset"} to={"/logout"}>Вихід</Link></NavDropdown.Item>
            </NavDropdown>
        </Navbar.Collapse>
    );
}