import React, {useRef, useState} from 'react';
import {Redirect, Route, useHistory, useLocation} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {selectIsAuthenticated} from "../redux/Selectors";
import {setIsAuthenticated, stepProcessingCounter} from '../redux/Actions';
import {Logger} from "../services/Logging";
import {WarningModal} from "./UIElements";

// Bootstrap
import Form from "react-bootstrap/Form";
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";
import FormGroup from "react-bootstrap/FormGroup";

export default function LoginPage() {
    const isAuthenticated = useSelector(selectIsAuthenticated);

    const dispatch = useDispatch();
    const formRef = useRef();

    // Warnings:
    const [showPswrdWarn, setShowPswrdWarn] = useState(false);

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');

    const location = useLocation();
    const history = useHistory();

    if (isAuthenticated) {
        let { from } = location.state || { from: { pathname: "/transactions" } };
        return <Redirect to={from} />
    }

    const handleLogin = e => {
        e.preventDefault();
        dispatch(stepProcessingCounter(1));

        fetch(formRef.current.action, {
            method: formRef.current.method,
            body: new URLSearchParams(new FormData(formRef.current)),
            headers: {'content-type' : 'application/x-www-form-urlencoded'},
            credentials: 'include'
        }).then(response => {
            if (response.status === 200) {
                dispatch(setIsAuthenticated(true));
                let { from } = location.state || { from: { pathname: "/main" } };
                history.replace(from);
            }
            if (response.status === 401) {
                setShowPswrdWarn(true);
            }
        })
        .catch(Logger.exception)
        .finally(() => {
            dispatch(stepProcessingCounter(-1));
        });
    };

    return(
        <Container style={{height: '80vh', background: 'url(/password.svg) no-repeat center bottom'}} className={"d-flex justify-content-center align-items-center"}>
            <Form className={"p-4 glass"} style={{width: '20rem'}} ref={formRef} onSubmit={handleLogin} action={`${process.env.REACT_APP_HOST}/api/public/login`} method="post">
                <FormGroup className={"mb-3"} label={"логін"}>
                    <Form.Label>Логін</Form.Label>
                    <Form.Control required type={"email"} placeholder={"логін"} name="username" value={username} onChange={e => setUsername(e.target.value)} />
                </FormGroup>
                <FormGroup className={"mb-3"} label={"пароль"}>
                    <Form.Label>Пароль</Form.Label>
                    <Form.Control required type={"password"} placeholder={"пароль"} name="password" value={password} onChange={e => setPassword(e.target.value)} />
                </FormGroup>
                <Form.Text>
                    Ще немає профілю? <a href={"/registration"}>Реєструйтесь тут</a>
                </Form.Text>
                <Button className={"w-100 mt-2"} variant={"primary"} type="submit">Вхід</Button>
                <Form.Text>Використовуючи сервіс Ви погоджуюсь з угодою користувача</Form.Text>
            </Form>
            <WarningModal show={showPswrdWarn} message={"Неправильний пароль!"} onOk={() => setShowPswrdWarn(false)} />
        </Container>
    );
}

export function PrivateRoute({children, ...rest}) {
    const isAuthenticated = useSelector(selectIsAuthenticated);

    return(
        <Route
            {...rest}
            render={({location}) => {
                return isAuthenticated ?
                children
                :
                <Redirect
                    to={{
                    pathname: "/login",
                    state: { from: location }
                    }}/>
            }}/>
    );
}