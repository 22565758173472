export function formatAddress(address = {}) {
    let result = "";
    if (address['region']) {
        result += `${address['region']} обл.`;
    }
    if (address['city']) {
        result += `, ${address['city']}`;
    }
    if (address['street']) {
        result += `, ${address['street']}`;
    }
    if (address['building']) {
        result += `, буд. ${address['building']}`;
    }
    if (address['apartments']) {
        result += `, кв. ${address['apartments']}`;
    }
    if (address['postalCode']) {
        result += `, ${address['postalCode']}`;
    }
    return result;
}

export function transliterate(ukText = "") {
    const dictionary = {
        "А": "A",
        "а": "a",
        "Б": "B",
        "б": "b",
        "В": "V",
        "в": "v",
        "Г": "H",
        "г": "h",
        "Ґ": "G",
        "ґ": "g",
        "Д": "D",
        "д": "d",
        "Е": "E",
        "е": "e",
        "Є": "Ye",
        "є": "ie",
        "Ж": "Zh",
        "ж": "zh",
        "З": "Z",
        "з": "z",
        "И": "Y",
        "и": "y",
        "І": "I",
        "і": "i",
        "Ї": "Yi",
        "ї": "yi",
        "Й": "I",
        "й": "i",
        "К": "K",
        "к": "k",
        "Л": "L",
        "л": "l",
        "М": "M",
        "м": "m",
        "Н": "N",
        "н": "n",
        "О": "O",
        "о": "o",
        "П": "P",
        "п": "p",
        "Р": "R",
        "р": "r",
        "С": "S",
        "с": "s",
        "Т": "T",
        "т": "t",
        "У": "U",
        "у": "u",
        "Ф": "F",
        "ф": "f",
        "Х": "K",
        "х": "kh",
        "Ц": "Ts",
        "ц": "ts",
        "Ч": "Ch",
        "ч": "ch",
        "Ш": "Sh",
        "ш": "sh",
        "Щ": "Shch",
        "щ": "shch",
        "Ь": "",
        "ь": "",
        "Ю": "Yu",
        "ю": "iu",
        "Я": "Ya",
        "я": "ia",
    }
    return ukText.split("").map(letter => letter in dictionary ? dictionary[letter] : letter).join("");
}

export function roundToCents(number) {
    return Math.round((number + Number.EPSILON) * 100) / 100;
}

export function fillFoldersWithEntrepreneurs(folders, entrepreneurs) {

    folders.forEach(folder => {
        folder.entrepreneurs = [];
        entrepreneurs.filter(entrepreneur => entrepreneur.folder !== null).forEach(entrepreneur => {
            if (folder.id === entrepreneur.folder.id) {
                folder.entrepreneurs.push(entrepreneur);
            }
        });
    });

    const noFolderEntrepreneurs = entrepreneurs.filter(entrepreneur => entrepreneur.folder === null);

    return {folders, noFolderEntrepreneurs};

}