import {Server4xxError, Server5xxError} from "../exceptions/Exceptions";
import {Logger} from "./Logging";

export async function handleFetch(url, parameters) {
    url = process.env.REACT_APP_HOST + url;
    const response = await fetch(url, {...parameters, credentials: 'include'});

    Logger.log(`[ ${parameters.method} ] ${url}: ${response.status}`);

    if (response.status >= 500) {
        return Promise.reject(new Server5xxError(url, parameters.method, response.status));
    }
    if (response.status >= 400) {
        try {
            const errorMessage = await response.json();
            return Promise.reject(new Server4xxError(url, parameters.method, response.status, errorMessage?.message));
        } catch (e) {
            return Promise.reject(new Server4xxError(url, parameters.method, response.status));
        }
    }
    return Promise.resolve(response);
}

export async function heartbeat() {
    const url = '/api/v1/heartbeat';
    await handleFetch(url, {
        method: 'GET'
    });
}

export async function logout() {
    const url = '/api/public/logout';
    await handleFetch(url, {
        method: 'GET'
    });
}

export async function getDpis() {
    const url = '/api/v1/gov-data/dpis';
    const response = await handleFetch(url, {
        method: 'GET',
        headers: { 'accept': 'application/json' }
    });
    return response.json();
}

export async function getKveds() {
    const url = '/api/v1/gov-data/kveds';
    const response = await handleFetch(url, {
        method: 'GET',
        headers: { 'accept': 'application/json' }
    });
    return response.json();
}

export async function getIncomeLimits(year) {
    const url = `/api/v1/gov-data/income-limits/${year}`;
    const response = await handleFetch(url, {
        method: 'GET',
        headers: { 'accept': 'application/json' }
    });
    return response.json();
}

export async function postEntrepreneur(entrepreneur) {
    const url = '/api/v1/entrepreneurs';
    const response = await handleFetch(url, {
        method: 'POST',
        headers: { 'content-type': 'application/json', 'accept': 'application/json' },
        body: JSON.stringify(entrepreneur)
    });
    return response.json();
}

export async function putEntrepreneur(entrepreneur) {
    const url = '/api/v1/entrepreneurs';
    const response = await handleFetch(url, {
        method: 'PUT',
        headers: { 'content-type': 'application/json', 'accept': 'application/json' },
        body: JSON.stringify(entrepreneur)
    });
    return response.json();
}

export async function deleteEntrepreneur(entrepreneurId) {
    const url = `/api/v1/entrepreneurs/${entrepreneurId}`;
    await handleFetch(url, {
        method: 'DELETE'
    });
}

export async function getEntrepreneurs() {
    const url = '/api/v1/entrepreneurs';
    const response = await handleFetch(url, {
        method: 'GET',
        headers: { 'accept': 'application/json' }
    });
    return response.json();
}

export async function getFolders() {
    const url = '/api/v1/folders';
    const response = await handleFetch(url, {
        method: 'GET',
        headers: { 'accept': 'application/json' }
    });
    return response.json();
}

export async function postFolder(folder) {
    const url = '/api/v1/folders';
    const response = await handleFetch(url, {
        method: 'POST',
        headers: { 'content-type': 'application/json', 'accept': 'application/json' },
        body: JSON.stringify(folder)
    });
    return response.json();
}

export async function putFolder(folder) {
    const url = '/api/v1/folders';
    const response = await handleFetch(url, {
        method: 'PUT',
        headers: { 'content-type': 'application/json', 'accept': 'application/json' },
        body: JSON.stringify(folder)
    });
    return response.json();
}

export async function putEntrepreneurFolder(folderId, entrepreneurId) {
    const url = `/api/v1/folders/${folderId}/${entrepreneurId}`;
    const response = await handleFetch(url, {
        method: 'PUT',
        headers: { 'content-type': 'application/json', 'accept': 'application/json' },
    });
    return response.json();
}

export async function deleteEntrepreneurFolder(folderId, entrepreneurId) {
    const url = `/api/v1/folders/${folderId}/${entrepreneurId}`;
    await handleFetch(url, {
        method: 'DELETE',
        headers: { 'content-type': 'application/json' },
    });
}

export async function deleteFolder(folderId) {
    const url = `/api/v1/folders/${folderId}`;
    await handleFetch(url, {
        method: 'DELETE'
    });
}

export async function postTask(taskDescription) {
    const url = '/api/v1/tasks';
    const response = await handleFetch(url, {
        method: 'POST',
        headers: { 'content-type': 'application/json', 'accept': 'application/json' },
        body: JSON.stringify(taskDescription)
    });
    return response.json();
}

export async function putTask(task) {
    const url = '/api/v1/tasks';
    const response = await handleFetch(url, {
        method: 'PUT',
        headers: { 'content-type': 'application/json', 'accept': 'application/json' },
        body: JSON.stringify(task)
    });
    return response.json();
}

export async function getTasks() {
    const url = `/api/v1/tasks`;
    const response = await handleFetch(url, {
        method: 'GET',
        headers: { 'accept': 'application/json' }
    });
    return response.json();
}

export async function deleteTask(scheduledId, series) {
    const url = `/api/v1/tasks/${scheduledId}?series=${series}`;
    await handleFetch(url, {
        method: 'DELETE'
    });
}

export async function postTransactions(entrepreneurId, transactions) {
    const url = `/api/v1/transactions/${entrepreneurId}`;
    const response = await handleFetch(url, {
        method: 'POST',
        headers: { 'content-type': 'application/json', 'accept': 'application/json' },
        body: JSON.stringify(transactions)
    });
    return response.json();
}

export async function putTransaction(transaction) {
    const url = `/api/v1/transactions`;
    const response = await handleFetch(url, {
        method: 'PUT',
        headers: { 'content-type': 'application/json', 'accept': 'application/json'},
        body: JSON.stringify(transaction)
    });
    return response.json();
}

export async function deleteTransactions(transactionIds) {
    const url = `/api/v1/transactions`;
    await handleFetch(url, {
        method: 'DELETE',
        headers: { 'content-type': 'application/json' },
        body: JSON.stringify(transactionIds)
    });
}

export async function getTransactions(entrepreneurId, fromDate, toDate) {
    const url = `/api/v1/transactions/${entrepreneurId}?fromDate=${encodeURIComponent(fromDate)}&toDate=${encodeURIComponent(toDate)}`;
    const response = await handleFetch(url, {
        method: 'GET',
        headers: { 'accept': 'application/json' }
    });
    return response.json();
}

export async function getOrganizationData() {
    const url = `/api/v1/organization`;
    const response = await handleFetch(url, {
        method: 'GET',
        headers: { 'accept': 'application/json' }
    });
    return response.json();
}

export async function getProfileData() {
    const url = `/api/v1/profile`;
    const response = await handleFetch(url, {
        method: 'GET',
        headers: { 'accept': 'application/json' }
    });
    return response.json();
}

export async function updateProfileData(profile) {
    const url = `/api/v1/profile`;
    const response = await handleFetch(url, {
        method: 'PUT',
        headers: { 'content-type': 'application/json', 'accept': 'application/json'},
        body: JSON.stringify(profile)
    });
    return response.json();
}

export async function updateProfilePassword(passwordDto) {
    const url = `/api/v1/profile/password`;
    await handleFetch(url, {
        method: 'PUT',
        headers: { 'content-type': 'application/json' },
        body: JSON.stringify(passwordDto)
    });
}

export async function updateOrganizationAccessKey(organization) {
    const url = `/api/v1/organization`;
    await handleFetch(url, {
        method: 'PUT',
        headers: { 'content-type': 'application/json' },
        body: JSON.stringify(organization)
    });
}

export async function createOrganizationMember(employee) {
    const url = `/api/v1/employee`;
    const response = await handleFetch(url, {
        method: 'POST',
        headers: { 'content-type': 'application/json' },
        body: JSON.stringify(employee)
    });
    return response.json();
}

export async function updateOrganizationMemberRole(employeeId, role) {
    const url = `/api/v1/employee/${employeeId}/role/${role}`;
    const response = await handleFetch(url, {
        method: 'PUT',
        headers: { 'accept': 'application/json' }
    });

    return response.json();
}

export async function deleteOrganizationMember(employeeId) {
    const url = `/api/v1/employee/${employeeId}`;
    await handleFetch(url, {
        method: 'DELETE'
    });
}

export function fetchDocuments(entrepreneurId, docType) {
    const url = `/api/v1/documents/${entrepreneurId}/${docType}`;
    return handleFetch(url, {
        method: 'GET',
        headers: { 'accept': 'application/json' }
    }).then(response => response.json());
}

export function deleteDocument(entrepreneurId, documentId) {
    const url = `/api/v1/documents/${entrepreneurId}/${documentId}`;
    return handleFetch(url, {
        method: 'DELETE',
    });
}

export function postDocument(entrepreneurId, docType, document) {
    const url = `/api/v1/documents/${entrepreneurId}/${docType}`;
    return handleFetch(url, {
        method: 'POST',
        headers: { 'content-type': 'text/plain', 'accept': 'application/json' },
        body: document
    }).then(response => response.json());
}