import React, {useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {selectEntrepreneur, selectEntrepreneurs, selectFolders, selectIsAuthenticated} from "../redux/Selectors";
import {
    createFolder,
    deleteEntrepreneurFolder,
    deleteFolder,
    setParsedTransactions,
    setPersistedTransactions,
    showMessage,
    updateEntrepreneurFolder,
    updateFolder
} from "../redux/Actions";
import {Link, useHistory, useParams} from "react-router-dom";
import {fillFoldersWithEntrepreneurs} from "../services/Utils";
import {CancellableInput, WarningModal} from "./UIElements";

import edit from "../resources/icons/edit.svg";
import expand from "../resources/icons/expand.svg";
import left from "../resources/icons/arrow-left.svg";

// Bootstrap
import {useAccordionButton} from "react-bootstrap";
import Accordion from "react-bootstrap/Accordion";
import Offcanvas from "react-bootstrap/Offcanvas";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import ListGroup from "react-bootstrap/ListGroup";
import DropdownButton from "react-bootstrap/DropdownButton";
import Dropdown from "react-bootstrap/Dropdown";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import {ERROR} from "./PopUps";
import Card from "react-bootstrap/Card";


export default function EntrepreneurBar() {
    const entrepreneurs = useSelector(selectEntrepreneurs);

    const [showBar, setShowBar] = useState(false);

    const isAuthenticated = useSelector(selectIsAuthenticated);

    if (!isAuthenticated) {
        return null;
    }

    return(
            <>
                <div style={{right: 0, width: '2rem'}}
                     className={"position-absolute h-100 d-flex flex-column justify-content-center align-items-center"}
                     role={"button"}>
                    <div className={"h-50 w-100 shadow rounded-start border d-flex flex-column justify-content-center align-items-center"}
                        onClick={() => setShowBar(true)}>
                        <img src={left} alt={"Розгорнути підприємців"}/>
                    </div>
                </div>
                <Offcanvas placement={"end"} show={showBar} onHide={() => setShowBar(false)}>
                    <Offcanvas.Header closeButton={true}>
                        <Offcanvas.Title>Підприємці {`(${entrepreneurs.length})`}</Offcanvas.Title>
                    </Offcanvas.Header>
                    <Offcanvas.Body>
                        <EntrepreneurBarBody entrepreneurs={entrepreneurs} onAction={() => setShowBar(false)} />
                    </Offcanvas.Body>
                </Offcanvas>
            </>
    );
}

function EntrepreneurBarBody({onAction}) {
    const dispatch = useDispatch();

    const { id } = useParams();
    const history = useHistory();
    const entrepreneurActive = useSelector(selectEntrepreneur(id));
    const entrepreneurs = useSelector(selectEntrepreneurs);
    const foldersList = useSelector(selectFolders);

    const [searchCriteria, setSearchCriteria] = useState('');

    const [isCreateFolder, setIsCreateFolder] = useState(false);

    const filterOnSearchCriteria = e => e.lastName.toUpperCase().startsWith(searchCriteria.toUpperCase()) || e.firstName.toUpperCase().startsWith(searchCriteria.toUpperCase());

    const filteredEntrepreneurs = entrepreneurs.filter(filterOnSearchCriteria);
    const {folders, noFolderEntrepreneurs} = fillFoldersWithEntrepreneurs(foldersList, filteredEntrepreneurs);

    const entrepreneurToListGroupItemMapper = (entrepreneur, folder) =>
        <ListGroup.Item role={"button"} key={entrepreneur.id}
                        active={entrepreneur?.id === entrepreneurActive?.id}
                        className={"user-select-none"}
                        onClick={() => {
                            dispatch(setPersistedTransactions([]));
                            dispatch(setParsedTransactions([]));
                            onAction();
                        }}
                        draggable={true}
                        onDragStart={evnt => {
                            evnt.dataTransfer.setData("text/plain", JSON.stringify({entrepreneurId: entrepreneur.id, sourceFolderId: folder ? folder.id : null}));
                            evnt.dataTransfer.dropEffect = "move";
                        }}>
            <Link to={`/entrepreneurs/${entrepreneur.id}`} className={"text-decoration-none text-reset"}>{`${entrepreneur.lastName} ${entrepreneur.firstName}`}</Link>
        </ListGroup.Item>;

    const handleCreateEntrepreneur = () => {
        history.push("/entrepreneurs/create");
        onAction();
    }

    const handleCreateFolder = (folderName) => {
        if (folderName.length < 3) {
            dispatch(showMessage("Помилка", "Назва папки повинна бути 3 і більше символів", ERROR));
        } else {
            dispatch(createFolder(folderName));
            setIsCreateFolder(false);
        }
    }

    return(
        <Container>
            <ButtonGroup className={"w-100"}>
                <Form.Control className={"mb-3 w-100"} type={"text"} placeholder={"Пошук"} value={searchCriteria} onChange={e => setSearchCriteria(e.target.value)} />
                <DropdownButton title={"Додати"}>
                    <Dropdown.Item onClick={handleCreateEntrepreneur}>Підприємця</Dropdown.Item>
                    <Dropdown.Item onClick={() => setIsCreateFolder(!isCreateFolder)}>Папку</Dropdown.Item>
                </DropdownButton>
            </ButtonGroup>
            <CancellableInput onOk={handleCreateFolder} onCancel={() => setIsCreateFolder(false)} className={"w-100 mb-3"} style={{display: isCreateFolder ? '' : 'none'}} />
            <Accordion defaultActiveKey={"0"}>
                {
                    folders.map(folder =>
                        <Folder key={folder.id} folder={folder}>
                            {
                                folder.entrepreneurs
                                    .map(entrepreneur => entrepreneurToListGroupItemMapper(entrepreneur, folder))
                            }
                        </Folder>
                    )
                }
            </Accordion>
            <ListGroup variant={"flush"}>
            {
                noFolderEntrepreneurs
                    .map(entrepreneurToListGroupItemMapper)
            }
            </ListGroup>
            <div style={{height: '200px'}}
                 onDragEnter={evnt => {
                     evnt.currentTarget.style.border = '3px solid lightblue';
                     evnt.preventDefault();
                 }}
                 onDragLeave={evnt => {
                     evnt.currentTarget.style.border = '';
                     evnt.preventDefault();
                 }}
                 onDrop={evnt => {
                     evnt.currentTarget.style.border = '';
                     const {entrepreneurId, sourceFolderId} = JSON.parse(evnt.dataTransfer.getData("text/plain"));
                     dispatch(deleteEntrepreneurFolder(sourceFolderId, entrepreneurId));
                 }}
                 onDragOver={(evnt) => {
                     // this is required for Drop to work
                     evnt.preventDefault();
                 }}
            ></div>
        </Container>
    );
}

function Folder({folder, children}) {
    const dispatch = useDispatch();

    const [isEditing, setIsEditing] = useState(false);
    const [isDeleteFolderModal, setIsDeleteFolderModal] = useState(false);

    function ExpandButton({eventKey}) {
        let onCLick = useAccordionButton(eventKey, () => {
            if (children.length === 0) {
                setIsDeleteFolderModal(true);
            }
        });
        return <img style={{cursor: "pointer"}} className={"ps-5 pe-1"} src={expand} alt={"розгорнути"} onClick={onCLick} />
    }

    function EditButton() {
        return <img style={{cursor: "pointer"}} className={"ms-3 hoverable"} src={edit} alt={"редагувати"} onClick={() => {
            setIsEditing(true);
        }}/> ;
    }

    return (
        <Card onDrop={evnt => {
                  evnt.currentTarget.style.border = '';
                  const {entrepreneurId, sourceFolderId} = JSON.parse(evnt.dataTransfer.getData("text/plain"));
                  if (folder.id !== sourceFolderId) {
                      dispatch(updateEntrepreneurFolder(folder.id, entrepreneurId));
                  }
              }}
              onDragOver={(evnt) => {
                  // this is required for Drop to work
                  evnt.preventDefault();
              }}>
            <WarningModal show={isDeleteFolderModal} header={folder.name}
                          message={`Папка ${folder.name} пуста, бажаєте видалити?`}
                          onCancel={() => setIsDeleteFolderModal(false)}
                          onOk={() => dispatch(deleteFolder(folder.id))} />
            <Card.Header style={{backgroundColor: 'white'}}>
                {
                    isEditing ?
                        <CancellableInput initialValue={folder.name} className={"w-75"}
                                          onCancel={() => setIsEditing(false)}
                                          onOk={name => {
                                              dispatch(updateFolder({name, id: folder.id}));
                                              setIsEditing(false);
                                          }}
                        />
                        :
                        <div className={"w-75 d-inline-block"}>
                            { folder.name }
                            <EditButton eventKey={folder.id} />
                        </div>
                }
                <ExpandButton eventKey={folder.id} />
            </Card.Header>
            <Accordion.Collapse eventKey={folder.id}>
                <Card.Body>
                    <ListGroup variant={"flush"}>
                        { children }
                    </ListGroup>
                </Card.Body>
            </Accordion.Collapse>
        </Card>
    );
}